* {
  font-family: 'Rajdhani', sans-serif;
  text-transform: uppercase;
}

@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');

/* CSS code for the form */

.socialIcons2 img:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.back:hover {
  transform: scale(1.1);
}

.page-loader .txt {
  color: #f56004;
  text-align: center;
  top: 40%;
  position: relative;
  text-transform: uppercase;
  letter-spacing: 0.3rem;
  font-weight: bold;
  line-height: 1.5;
}

.spinner {
  position: relative;
  top: 35%;
  width: 80px;
  height: 80px;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  100% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
    opacity: 0;
  }
}

.rocks {
  width: 100%;
  animation: floatAnimation 5s ease-in-out infinite;
  margin-top: -10%;
}

.rocks2 {
  width: 80%;
  animation: floatAnimation 4s ease-in-out infinite;
  margin-top: -30%;
}

.rocks3 {
  width: 100%;
  animation: floatAnimation 6s ease-in-out infinite;
  margin-top: -50%;
}

.rocks4 {
  width: 100%;
  animation: floatAnimation 5s ease-in-out infinite;
  margin-top: -100%;
}

@keyframes floatAnimation {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.mintLine {
  width: 100%;
  margin-top: -40px;
  position: sticky;
  bottom: 0;
  position: relative;
}

.mountains {
  position: relative;
}

::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(51, 203, 241);
}


@media screen and (max-width: 499px) {

  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 30px;

  }

  .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: transform .2s;
  }

  .pay{
    width: 35px;
    height: 35px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

  .back{
    width: 30px;
    height: 30px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }

  .success-message h2 {
    font-size: 20px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 18px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 30px;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 30px;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: white;
  }


  .alienship {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: rgb(7, 0, 14);
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .shopP {
    font-size: 23px;
  }

  .aboutShop {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -27%;
  }


  .page-loader {
    width: 100%;
    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
   }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 25vh;
  }

  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitVid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10px;
  }

  .about3 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .subscribe {
    font-size: 18px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 10px;
    transition: transform .2s;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;

  }

  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 90%;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 30px;
  }

  .inputMain {
    margin-bottom: 30px;
  }

  .inputMain .inputH {
    color: white;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .boxWrapRm {
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/Bunny-roadmaMobp.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #00000000;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .logo2 img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10000000000000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0eb7fa 1px solid;
    z-index: 10000;

  }

  .littleNav {
    background-color: #1a1a1ac9;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0eb7fa solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #00000000;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
    width: 100%;
  }

  .logoM {
    width: 130px;
    height: 130px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: -60px;
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    animation: glow2 2s infinite alternate;
  }

  @keyframes glow2 {
    0% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }

    50% {
      box-shadow: 0 0 25px 15px rgba(133, 193, 248, 0.7);
    }

    100% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 25%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
  .imgs3 img {
    width: 30%;
    height: 30%;
  }
  */
  .leftPlanet {
    width: 40%;
    height: 40%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
    width: 150vh;
    height: 150vh;
    margin-left: -40vw;
    margin-top: -15%;
  }
  
  .rightGalaxy {
    width: 150vh;
    height: 150vh;
    margin-right: -35vw;
    margin-top: -15%;
  }*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .rocket {
    margin-left: 34%;
    display: flex;
    justify-content: center;
    width: 30%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 34%;
    display: flex;
    justify-content: center;
    width: 30%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 42%;
    width: 12%;
    position: absolute;
    margin-left: 43%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 52%;
    width: 12%;
    position: absolute;
    margin-left: 43%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 50px;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 2px;
    text-align: center;
    margin-top: 3vh;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;

  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 19px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 94, 142, 0) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/mobBg3.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
  }

  .rmMain {
    position: relative;
    height: fit-content;
    display: none;
  }

  .rmMobile img {
    margin-top: 15%;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .rmMobile {
    flex-flow: column nowrap;
    display: flex;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 6%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 25px;
    height: 25px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10%;

  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 30px;
  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 50px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 60px;
    z-index: 1;
    margin-bottom: 30px;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 50px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 16px;
    width: 90%;
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .storyCon p {
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .astronaut {
    width: 50%;
    margin-left: 30%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 40%;
    animation: float 6s ease-in-out infinite;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-left: 60%;
    display: flex;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    padding-top: 6%;
    padding-bottom: 6%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 50px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 18px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;

    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 23px;
    width: 250px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 70px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 10px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }







  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .box1 img {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .box1 {
    padding: 30px;
  }

  .name {
    text-align: center;
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    text-align: center;
    font-size: 20px;
    color: #01a5c2;
  }

  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 180px;
    height: 180px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 100%;
    margin-left: -10%;
    bottom: 0;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    z-index: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .page-loader {

    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
   }

   .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: transform .2s;
  }

   .pay{
    width: 35px;
    height: 35px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

   .back{
    width: 40px;
    height: 40px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }

  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 30px;

  }

  .success-message h2 {
    font-size: 22px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 20px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 30px;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 30px;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: white;
  }



  .alienship {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .aboutShop {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -24%;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: rgb(7, 0, 14);
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 25vh;
  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;

  }

  .rabbitVid {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10px;
  }

  .about3 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .subscribe {
    font-size: 18px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 10px;
    transition: transform .2s;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;

  }

  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 30px;
  }

  .inputMain {
    margin-bottom: 30px;
  }

  .inputMain .inputH {
    color: white;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .boxWrapRm {
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/Bunny-roadmaMobp.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #00000000;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .logo2 img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10000000000000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0eb7fa 1px solid;
    z-index: 10000;

  }

  .littleNav {
    background-color: #1a1a1ac9;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0eb7fa solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;

  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    contain: content;
    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
    width: 100%;
  }

  .logoM {
    width: 130px;
    height: 130px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: -60px;
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    animation: glow2 2s infinite alternate;
  }

  @keyframes glow2 {
    0% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }

    50% {
      box-shadow: 0 0 25px 15px rgba(133, 193, 248, 0.7);
    }

    100% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }
  }


  .logoDiv {
    display: flex;
    justify-content: center;
    width: 25%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
  .imgs3 img {
    width: 30%;
    height: 30%;
  }
  */
  .leftPlanet {
    width: 40%;
    height: 40%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
    width: 150vh;
    height: 150vh;
    margin-left: -40vw;
    margin-top: -15%;
  }
  
  .rightGalaxy {
    width: 150vh;
    height: 150vh;
    margin-right: -35vw;
    margin-top: -15%;
  }*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .rocket {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 15%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 15%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 26.5%;
    width: 5%;
    position: absolute;
    margin-left: 46.8%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 28%;
    width: 5%;
    position: absolute;
    margin-left: 46.8%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 60px;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 4px;
    text-align: center;
    margin-top: 3vh;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;

  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 19px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 94, 142, 0) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/mobBg3.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
  }



  .rmMain {
    position: relative;
    height: fit-content;
    display: none;
  }

  .rmMobile img {
    margin-top: 10%;
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  .rmMobile {
    flex-flow: column nowrap;
    display: flex;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 6%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 25px;
    height: 25px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 10%;

  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 30px;
  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 50px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 80px;
    z-index: 1;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 55px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    z-index: 1;
    text-align: center;
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 16px;
    width: 90%;
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .storyCon p {
    color: white;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .astronaut {
    width: 30%;
    margin-left: 30%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 40%;
    animation: float 6s ease-in-out infinite;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-left: 60%;

  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 23px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 10px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }







  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .box1 img {
    width: 280px;
    height: 280px;
    border-radius: 100%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }



  .name {
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 2px;
    font-size: 30px;
  }

  .name2 {
    text-align: center;
    font-size: 20px;
    color: #01a5c2;
  }

  summary {
    font-size: 18px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .icons img {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 200px;
    height: 200px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 100%;
    margin-left: -20%;
    bottom: 0;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    z-index: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 30px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }

  .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: transform .2s;
  }

  .pay{
    width: 35px;
    height: 35px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }


  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 30px;

  }
  .back{
    width: 40px;
    height: 40px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }
  .success-message h2 {
    font-size: 22px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 20px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 30px;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 30px;
  }

  .loadingText {
    font-size: 18px;
    text-align: center;
    color: white;
  }


  .alienship {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .aboutShop {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: rgb(7, 0, 14);
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .page-loader {
    width: 100%;
    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
  }

  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    margin-top: 15vh;

  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .rabbitVid {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10px;
  }

  .about3 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .subscribe {
    font-size: 18px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 10px;
    transition: transform .2s;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;

  }

  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 30px;
  }

  .inputMain {
    margin-bottom: 30px;
  }

  .inputMain .inputH {
    color: white;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #00000000;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .logo2 img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 10000000000000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0eb7fa 1px solid;
    z-index: 10000;

  }

  .littleNav {
    background-color:  #1a1a1ac9;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0eb7fa solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    background-color: #0000009a;
    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;

  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
  }

  .logoM {
    width: 160px;
    height: 160px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: -60px;
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    animation: glow2 2s infinite alternate;
  }

  @keyframes glow2 {
    0% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }

    50% {
      box-shadow: 0 0 25px 15px rgba(133, 193, 248, 0.7);
    }

    100% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 25%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
  .imgs3 img {
    width: 30%;
    height: 30%;
  }
  */
  .leftPlanet {
    width: 40%;
    height: 40%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
    width: 150vh;
    height: 150vh;
    margin-left: -40vw;
    margin-top: -15%;
  }
  
  .rightGalaxy {
    width: 150vh;
    height: 150vh;
    margin-right: -35vw;
    margin-top: -15%;
  }*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }

  .rocket {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 15%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 15%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 26.5%;
    width: 5%;
    position: absolute;
    margin-left: 46.8%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 28%;
    width: 5%;
    position: absolute;
    margin-left: 46.8%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 80px;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 4px;
    text-align: center;
    margin-top: 3vh;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;

  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 3rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 3px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 19px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 94, 142, 0) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/galaxy.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
  }


  /*ROADMAP*/

  .boxWrapRm {
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/Bunny-roadmaMobp.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .layer1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
  }

  .layer2Box {
    position: absolute;
    margin-top: 20.2%;
    left: 6.9%;
    /* Adjust the distance from the image */
    width: 16.5%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer2:hover+.layer2Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer2 {
    width: 5.5%;
    margin-left: 16.5%;
    margin-top: 17.1%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3 {
    width: 9%;
    margin-left: 19.8%;
    margin-top: 1.3%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3Box {
    position: absolute;
    margin-top: 1%;
    left: 25%;
    /* Adjust the distance from the image */
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer3:hover+.layer3Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer4 {
    width: 14%;
    margin-left: 37%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer4Box {
    position: absolute;
    margin-top: 2%;
    left: 45%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer4:hover+.layer4Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer5 {
    width: 20%;
    margin-left: 62.5%;
    margin-top: 2.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer5Box {
    position: absolute;
    margin-top: 2%;
    left: 75%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer5:hover+.layer5Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer6 {
    width: 14%;
    margin-left: 43.5%;
    margin-top: 23.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer6Box {
    position: absolute;
    margin-top: 31%;
    left: 31%;
    /* Adjust the distance from the image */
    width: 17%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer6:hover+.layer6Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer7 {
    width: 41%;
    margin-left: 59%;
    margin-top: 22.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer7Box {
    position: absolute;
    margin-left: 75%;
    margin-top: 38%;
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer7:hover+.layer7Box {
    opacity: 1;
    z-index: 10000000;
  }

  .rmMain {
    position: relative;
    height: fit-content;
    display: none;
  }

  .rmMobile img {
    margin-top: 5%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }

  .rmMobile {
    flex-flow: column nowrap;
    display: flex;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 6%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 25px;
    height: 25px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;

  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
    margin-bottom: 30px;

  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 70px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 80px;
    z-index: 1;
    text-align: center;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 70px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 120px;
    z-index: 1;
    text-align: center;
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 18px;
    width: 90%;
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .astronaut {
    width: 30%;
    margin-left: 30%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 30%;
    animation: float 6s ease-in-out infinite;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-left: 55%;

  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 10px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }







  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }

  .box1 img {
    width: 330px;
    height: 330px;
    border-radius: 100%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .box1 {
    padding: 30px;
  }

  .name {
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 2px;
    font-size: 25px;
  }

  .name2 {
    text-align: center;
    font-size: 17px;
    color: #01a5c2;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .icons img {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 60%;
    margin-left: -10%;
    bottom: 0;
    z-index: 1;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    z-index: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 35px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  body {
    overflow-x: hidden;
  }

  .back{
    width: 40px;
    height: 40px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }
  .pay{
    width: 35px;
    height: 35px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }


  .alienship {
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .aboutShop {
    width: 40%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -20%;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: rgb(7, 0, 14);
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .page-loader {
    width: 100%;
    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
  }

  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 85%;
    margin-top: 15vh;
  }

  .logoM {
    width: 160px;
    height: 160px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    margin-top: -60px;
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    animation: glow2 2s infinite alternate;
  }

  .rabbitVid {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  @keyframes glow2 {
    0% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }

    50% {
      box-shadow: 0 0 25px 15px rgba(133, 193, 248, 0.7);
    }

    100% {
      box-shadow: 0 0 10px 5px rgba(133, 193, 248, 0.5);
    }
  }

  .rabbitWithFlag {
    width: 27%;
    position: absolute;
    margin-right: 90%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    transform: rotate(7deg);
  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10px;
  }

  .about3 {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .subscribe {
    font-size: 18px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 10px;
    transition: transform .2s;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;

  }

  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 30px;
  }

  .inputMain {
    margin-bottom: 30px;
  }

  .inputMain .inputH {
    color: white;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .boxWrap2FooterM {
    display: none;
  }

  .rmMobile {
    display: none;
  }

  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    justify-content: space-between;
    z-index: 1000000;
  }

  .logo2 img {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 30px;
    justify-content: flex-end;
    cursor: pointer;
    margin-top: auto;
    margin-bottom: auto;
    z-index: 1000000000000000000;
    margin-top: 20px;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: #0eb7fa 1px solid;
    z-index: 10000;

  }

  .littleNav {
    background-color: #1a1a1ac9;
    width: 100%;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px #0eb7fa solid;
    z-index: 10000;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 85px;
    height: 85px;
    justify-content: flex-end;
    margin-left: auto;

    cursor: pointer;
    transition: transform .3s;

  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;

    z-index: 1000000;
    display: none;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg1.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
  }

  .logo {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    box-shadow: 0 0 10px 4px rgba(133, 193, 248, 0.5);
    z-index: 12;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 20%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
  .imgs3 img {
    width: 30%;
    height: 30%;
  }
  */
  .leftPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;

  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
    width: 150vh;
    height: 150vh;
    margin-left: -40vw;
    margin-top: -15%;
  }
  
  .rightGalaxy {
    width: 150vh;
    height: 150vh;
    margin-right: -35vw;
    margin-top: -15%;
  }*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 90%;
  }


  .rocket {
    margin-left: 44%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 44%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 18%;
    width: 5%;
    position: absolute;
    margin-left: 47.32%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 17%;
    width: 5%;
    position: absolute;
    margin-left: 47.32%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 80px;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 6px;
    margin-top: 5%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;

  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 22px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 40px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 95, 142, 0.5242471988795518) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/galaxy.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
  }

  .boxWrap2Footer {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  /*ROADMAP*/

  .boxWrapRm {
    background: rgb(0, 0, 0);

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .layer1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
  }

  .layer2Box {
    position: absolute;
    margin-top: 20.2%;
    left: 6.9%;
    /* Adjust the distance from the image */
    width: 16.5%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer2:hover+.layer2Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer2 {
    width: 5.5%;
    margin-left: 16.5%;
    margin-top: 17.1%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3 {
    width: 9%;
    margin-left: 19.8%;
    margin-top: 1.3%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3Box {
    position: absolute;
    margin-top: 1%;
    left: 25%;
    /* Adjust the distance from the image */
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer3:hover+.layer3Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer4 {
    width: 14%;
    margin-left: 37%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer4Box {
    position: absolute;
    margin-top: 2%;
    left: 45%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer4:hover+.layer4Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer5 {
    width: 20%;
    margin-left: 62.5%;
    margin-top: 2.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer5Box {
    position: absolute;
    margin-top: 2%;
    left: 75%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer5:hover+.layer5Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer6 {
    width: 14%;
    margin-left: 43.5%;
    margin-top: 23.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer6Box {
    position: absolute;
    margin-top: 31%;
    left: 31%;
    /* Adjust the distance from the image */
    width: 17%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer6:hover+.layer6Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer7 {
    width: 41%;
    margin-left: 59%;
    margin-top: 22.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer7Box {
    position: absolute;
    margin-left: 75%;
    margin-top: 38%;
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer7:hover+.layer7Box {
    opacity: 1;
    z-index: 10000000;
  }

  .rmMain {
    position: relative;
    height: fit-content;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 3%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 56.4%;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: transform .2s;
  }

  .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;

  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 85%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 80px;
    z-index: 1;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 80px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 120px;
    z-index: 1;
    text-align: center;
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 18px;
    width: 60%;
    color: white;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .astronaut {
    width: 25%;
    margin-left: 28%;
    margin-top: -32%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 25%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 55%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 80px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 10px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }



  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 5%;

  }

  .success-message h2 {
    font-size: 25px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 23px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 5%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 5%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }


  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 10px;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);

  }

  .box1 {
    padding: 30px;
  }

  .name {
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 2px;
    font-size: 25px;
  }

  .name2 {
    text-align: center;
    font-size: 17px;
    color: #01a5c2;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 80%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
    margin-bottom: 3%;
  }

  .icons img {
    width: 60px;
    height: 60px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 250px;
    height: 250px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 40%;
    margin-left: -10%;
    bottom: 0;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 35px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {

  .back{
    width: 40px;
    height: 40px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }

  .pay{
    width: 35px;
    height: 35px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }


  .alienship {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .aboutShop {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15%;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  body {
    overflow-x: hidden;
  }

  .page-loader {
    width: 100%;
    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
  }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 65%;
    margin-top: 15vh;
  }

  .logoM {
    display: none;
  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .rabbitVid {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 10px;
  }

  .about3 {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }


  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 400px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 30px;
  }

  .inputMain {
    margin-bottom: 30px;
  }

  .inputMain .inputH {
    color: white;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .boxWrap2FooterM {
    display: none;
  }

  .rmMobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.719);
    margin-top: -8px;
    padding-top: 13px;
    padding-bottom: 13px;

    /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 18px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 18px;
    padding-left: 30px;
    padding-right: 30px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg1.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
  }

  .logo {
    width: 80px;
    height: 80px;
    border-radius: 100px;
    border: 3px solid rgb(66, 157, 243);
    box-shadow: 0 0 10px 4px rgba(133, 193, 248, 0.5);
    z-index: 12;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 20%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
.imgs3 img {
  width: 30%;
  height: 30%;
}
*/
  .leftPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
  width: 150vh;
  height: 150vh;
  margin-left: -40vw;
  margin-top: -15%;
}

.rightGalaxy {
  width: 150vh;
  height: 150vh;
  margin-right: -35vw;
  margin-top: -15%;
}*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 80%;
  }

  .rocket {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 18%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 17%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 90px;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 6px;
    margin-top: 5%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;

  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 4rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 4px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 4rem;
    line-height: 4rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 22px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 8rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 8rem;
    line-height: 8rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 40px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 95, 142, 0.5242471988795518) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/galaxy.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
  }

  /*ROADMAP*/

  .boxWrapRm {
    background: rgb(0, 0, 0);

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .layer1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
  }

  .layer2Box {
    position: absolute;
    margin-top: 20.2%;
    left: 6.9%;
    /* Adjust the distance from the image */
    width: 16.5%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer2:hover+.layer2Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer2 {
    width: 5.5%;
    margin-left: 16.5%;
    margin-top: 17.1%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3 {
    width: 9%;
    margin-left: 19.8%;
    margin-top: 1.3%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3Box {
    position: absolute;
    margin-top: 1%;
    left: 25%;
    /* Adjust the distance from the image */
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer3:hover+.layer3Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer4 {
    width: 14%;
    margin-left: 37%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer4Box {
    position: absolute;
    margin-top: 2%;
    left: 45%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer4:hover+.layer4Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer5 {
    width: 20%;
    margin-left: 62.5%;
    margin-top: 2.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer5Box {
    position: absolute;
    margin-top: 2%;
    left: 75%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer5:hover+.layer5Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer6 {
    width: 14%;
    margin-left: 43.5%;
    margin-top: 23.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer6Box {
    position: absolute;
    margin-top: 31%;
    left: 31%;
    /* Adjust the distance from the image */
    width: 17%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer6:hover+.layer6Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer7 {
    width: 41%;
    margin-left: 59%;
    margin-top: 22.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .rabbitWithFlag {
    width: 27%;
    position: absolute;
    margin-right: 90%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    transform: rotate(7deg);
  }

  .layer7Box {
    position: absolute;
    margin-left: 75%;
    margin-top: 38%;
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer7:hover+.layer7Box {
    opacity: 1;
    z-index: 10000000;
  }

  .rmMain {
    position: relative;
    height: fit-content;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 3%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 56.4%;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;     
  }

  .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: transform .2s;
  }
  
  .socialIcons img {
    margin-right: 20px;
    width: 30px;
    height: 30px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;
  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 16px;
    font-size: 16px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    z-index: 100000000000000;
  }

  .conT {
    font-size: 75px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 80px;
    z-index: 1;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 100px;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    line-height: 120px;
    z-index: 1;
    text-align: center;
    margin-bottom: 50px;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 18px;
    width: 60%;
    color: white;
    z-index: 1000;
  }

  .storyCon p {
    color: white;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .astronaut {
    width: 20%;
    margin-left: 30%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 20%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 20px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 50px;
    margin-right: 50px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }


  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 10px;
    font-size: 25px;
    width: 300px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }


  .subscribe {
    font-size: 18px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 10px;
    transition: transform .2s;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 2px;

  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 85px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 10px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }

  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 5%;

  }

  .success-message h2 {
    font-size: 25px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 23px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 5%;
    font-size: 20px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 5%;
  }

  .loadingText {
    font-size: 20px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {

    width: 300px;
    height: 300px;
    border-radius: 100%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 10px;
    /*transition: transform .2s ease, box-shadow .2s ease;*/
    /* Add box-shadow transition */
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .box1 {
    padding: 30px;

  }

  /*.box1:hover {
  transform: rotate(-5deg);
  box-shadow: 10px -10px rgba(226, 226, 226, 0.685), 0 0 10px rgba(0, 0, 0, 0.3);
  /* Add box shadow for both element and border 
}*/

  .name {
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 2px;
    font-size: 30px;
    z-index: 100000000000;

  }

  .name2 {
    text-align: center;
    font-size: 18px;
    color: #01a5c2;
  }

  summary {
    font-size: 20px;
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;

  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 20px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 70px;
    height: 70px;
    margin-left: 20px;
    margin-right: 20px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 300px;
    height: 300px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }



  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 40%;
    margin-left: -10%;
    bottom: 0;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 35px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 2px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -2px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 1920px) {
  body {
    overflow-x: hidden;
  }
  
  .pay{
    width: 45px;
    height: 45px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

  .back{
    width: 50px;
    height:  50px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }

  .alienship {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .aboutShop {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15%;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: rgb(7, 0, 14);
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }


  .page-loader {
    width: 100%;
    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
  }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 65%;
    margin-top: 15vh;
  }

  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .logoM {
    display: none;
  }

  .rabbitVid {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitWithFlag {
    width: 27%;
    position: absolute;
    margin-right: 90%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    transform: rotate(7deg);
  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;
    font-size: 22px;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
    font-size: 22px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
    font-size: 22px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 15px;
    font-size: 22px;

  }

  .about3 {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 550px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 30px;
    font-size: 40px;
  }

  .inputMain {
    margin-bottom: 40px;
  }

  .inputMain .inputH {
    color: white;
    font-size: 22px;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 22px;

  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .boxWrap2FooterM {
    display: none;
  }

  .rmMobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    z-index: 1000000;
    background-color: rgba(0, 0, 0, 0.719);
    padding-top: 13px;
    padding-bottom: 13px;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 24px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 45px;
    margin-right: 45px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 24px;
    padding-left: 50px;
    padding-right: 50px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 2px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg1.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
  }

  .logo {
    width: 115px;
    height: 115px;
    border-radius: 100%;
    border: 5px solid rgb(66, 157, 243);
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    box-shadow: 0 0 10px 4px rgba(133, 193, 248, 0.5);
    z-index: 12;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 20%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
  .imgs3 img {
    width: 30%;
    height: 30%;
  }
  */
  .leftPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;

  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
    width: 150vh;
    height: 150vh;
    margin-left: -40vw;
    margin-top: -15%;
  }
  
  .rightGalaxy {
    width: 150vh;
    height: 150vh;
    margin-right: -35vw;
    margin-top: -15%;
  }*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 80%;
  }


  .rocket {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 18%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 17%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 130px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    letter-spacing: 8px;
    margin-top: 5%;
    text-shadow: 0 -1px 4px #FFF, 0 -2px 10px rgba(0, 195, 255, 0.377), 0 -10px 20px #0099ff63, 0 -18px 40px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;

  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 5.5px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 2rem;
    font-size: 30px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 11rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 11rem;
    line-height: 11rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 2rem;
    font-size: 60px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 95, 142, 0.5242471988795518) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/galaxy.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;

  }

  /*ROADMAP*/

  .boxWrapRm {
    background: rgb(0, 0, 0);

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .layer1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
  }

  .layer2Box {
    position: absolute;
    margin-top: 20.2%;
    left: 6.9%;
    /* Adjust the distance from the image */
    width: 16.5%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer2:hover+.layer2Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer2 {
    width: 5.5%;
    margin-left: 16.5%;
    margin-top: 17.1%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3 {
    width: 9%;
    margin-left: 19.8%;
    margin-top: 1.3%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3Box {
    position: absolute;
    margin-top: 1%;
    left: 25%;
    /* Adjust the distance from the image */
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer3:hover+.layer3Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer4 {
    width: 14%;
    margin-left: 37%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer4Box {
    position: absolute;
    margin-top: 2%;
    left: 45%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer4:hover+.layer4Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer5 {
    width: 20%;
    margin-left: 62.5%;
    margin-top: 2.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer5Box {
    position: absolute;
    margin-top: 2%;
    left: 75%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer5:hover+.layer5Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer6 {
    width: 14%;
    margin-left: 43.5%;
    margin-top: 23.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer6Box {
    position: absolute;
    margin-top: 31%;
    left: 31%;
    /* Adjust the distance from the image */
    width: 17%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer6:hover+.layer6Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer7 {
    width: 41%;
    margin-left: 59%;
    margin-top: 22.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer7Box {
    position: absolute;
    margin-left: 75%;
    margin-top: 38%;
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer7:hover+.layer7Box {
    opacity: 1;
    z-index: 10000000;
  }

  .rmMain {
    position: relative;
    height: fit-content;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 3%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 56.4%;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    transition: transform .2s;
  }

  .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 20px;
    width: 40px;
    height: 40px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;

  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 22px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 20px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 22px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 100px;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    line-height: 110px;
    z-index: 1;
    text-shadow: 0 -2px 5px #FFF, 0 -3px 11px rgba(0, 195, 255, 0.377), 0 -11px 21px #0099ff63, 0 -19px 41px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 130px;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    line-height: 160px;
    z-index: 1;
    text-align: center;
    margin-bottom: 50px;
    text-shadow: 0 -2px 5px #FFF, 0 -3px 11px rgba(0, 195, 255, 0.377), 0 -11px 21px #0099ff63, 0 -19px 41px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 25px;
    width: 60%;
    color: white;
    z-index: 1000;
  }

  .storyCon p {
    color: white;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .astronaut {
    width: 20%;
    margin-left: 32%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 20%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 27px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 60px;
    margin-right: 60px;
    padding: 5px;
    width: 100px;
    height: 100px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 4px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 15px;
    font-size: 35px;
    width: 400px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .subscribe {
    font-size: 24px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 10px;
    transition: transform .2s;
    border-width: 4px;

    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 4px;

  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 130px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 10px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }


  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 5%;

  }

  .success-message h2 {
    font-size: 33px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 30px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 5%;
    font-size: 27px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 5%;
  }

  .loadingText {
    font-size: 27px;
    text-align: center;
    color: white;
  }

  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 430px;
    height: 430px;
    border-radius: 100%;
    border: 3px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 15px;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);

    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);

  }

  .box1 {
    padding: 30px;
  }

  .name {
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 4px;
    font-size: 40px;
  }

  .name2 {
    text-align: center;
    font-size: 24px;
    color: #01a5c2;
  }

  summary {
    font-size: 27px;
    color: rgb(0, 0, 0);
    padding: 3rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 27px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 100px;
    height: 100px;
    margin-left: 30px;
    margin-right: 30px;
    box-shadow: 0 0 10px #01a5c2;
    border: 4px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 400px;
    height: 400px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 40%;
    margin-left: -10%;
    bottom: 0;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 10px;
    letter-spacing: 15px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 55px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 3px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -3px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  body {
    overflow-x: hidden;
  }

  .pay{
    width: 55px;
    height: 55px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

  .back{
    width: 60px;
    height: 60px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }
  .alienship {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .aboutShop {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15%;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: rgb(7, 0, 14);
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .page-loader {
    width: 100%;
    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
  }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 65%;
    margin-top: 15vh;
  }

  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .logoM {
    display: none;
  }

  .rabbitVid {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitWithFlag {
    width: 27%;
    position: absolute;
    margin-right: 90%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    transform: rotate(7deg);
  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;
    font-size: 30px;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
    font-size: 30px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
    font-size: 30px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 15px;
    font-size: 30px;

  }

  .about3 {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }


  .subscribe {
    font-size: 35px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 13px;
    transition: transform .2s;
    border-width: 4px;

    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 4px;

  }

  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 700px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 50px;
    font-size: 50px;
  }

  .inputMain {
    margin-bottom: 40px;
  }

  .inputMain .inputH {
    color: white;
    font-size: 28px;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 28px;

  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .boxWrap2FooterM {
    display: none;
  }

  .rmMobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    z-index: 1000000;
    padding-top: 13px;
    padding-bottom: 13px;
    background-color: rgba(0, 0, 0, 0.719);

    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 35px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 95px;
    margin-right: 95px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 35px;
    padding-left: 65px;
    padding-right: 65px;
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg1.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
  }

  .logo {
    width: 160px;
    height: 160px;
    border-radius: 100%;
    border: 6px solid rgb(66, 157, 243);
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    box-shadow: 0 0 10px 4px rgba(133, 193, 248, 0.5);
    z-index: 12;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 25%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
  .imgs3 img {
    width: 30%;
    height: 30%;
  }
  */
  .leftPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
    width: 150vh;
    height: 150vh;
    margin-left: -40vw;
    margin-top: -15%;
  }
  
  .rightGalaxy {
    width: 150vh;
    height: 150vh;
    margin-right: -35vw;
    margin-top: -15%;
  }*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 80%;
  }


  .rocket {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 18%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 17%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 160px;
    color: white;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    letter-spacing: 10px;
    margin-top: 5%;
    text-shadow: 0 -4px 6px #FFF, 0 -5px 13px rgba(0, 195, 255, 0.377), 0 -13px 23px #0099ff63, 0 -21px 43px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;

  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 7px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 40px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 14rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 14rem;
    line-height: 14rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 4rem;
    font-size: 80px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 95, 142, 0.5242471988795518) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/galaxy.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
  }



  /*ROADMAP*/

  .boxWrapRm {
    background: rgb(0, 0, 0);

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .layer1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
  }

  .layer2Box {
    position: absolute;
    margin-top: 20.2%;
    left: 6.9%;
    /* Adjust the distance from the image */
    width: 16.5%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer2:hover+.layer2Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer2 {
    width: 5.5%;
    margin-left: 16.5%;
    margin-top: 17.1%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3 {
    width: 9%;
    margin-left: 19.8%;
    margin-top: 1.3%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3Box {
    position: absolute;
    margin-top: 1%;
    left: 25%;
    /* Adjust the distance from the image */
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer3:hover+.layer3Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer4 {
    width: 14%;
    margin-left: 37%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer4Box {
    position: absolute;
    margin-top: 2%;
    left: 45%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer4:hover+.layer4Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer5 {
    width: 20%;
    margin-left: 62.5%;
    margin-top: 2.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer5Box {
    position: absolute;
    margin-top: 2%;
    left: 75%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer5:hover+.layer5Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer6 {
    width: 14%;
    margin-left: 43.5%;
    margin-top: 23.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer6Box {
    position: absolute;
    margin-top: 31%;
    left: 31%;
    /* Adjust the distance from the image */
    width: 17%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer6:hover+.layer6Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer7 {
    width: 41%;
    margin-left: 59%;
    margin-top: 22.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer7Box {
    position: absolute;
    margin-left: 75%;
    margin-top: 38%;
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer7:hover+.layer7Box {
    opacity: 1;
    z-index: 10000000;
  }

  .rmMain {
    position: relative;
    height: fit-content;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 3%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 56.4%;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 40px;
    width: 60px;
    height: 60px;
    transition: transform .2s;
  }

  .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 40px;
    width: 50px;
    height: 50px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;

  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 30px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 50px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 30px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {
    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 140px;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    line-height: 160px;
    z-index: 1;
    text-shadow: 0 -4px 6px #FFF, 0 -5px 13px rgba(0, 195, 255, 0.377), 0 -13px 23px #0099ff63, 0 -21px 43px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 170px;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    line-height: 210px;
    z-index: 1;
    text-align: center;
    margin-bottom: 100px;
    text-shadow: 0 -4px 6px #FFF, 0 -5px 13px rgba(0, 195, 255, 0.377), 0 -13px 23px #0099ff63, 0 -21px 43px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 33px;
    width: 60%;
    color: white;
    z-index: 1000;
  }

  .storyCon p {
    color: white;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .astronaut {
    width: 20%;
    margin-left: 32%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 20%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 100px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 35px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 90px;
    margin-right: 90px;
    padding: 5px;
    width: 140px;
    height: 140px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 4px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 45px;
    width: 580px;
    border-width: 4px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 170px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 20px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }


  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 5%;

  }

  .success-message h2 {
    font-size: 40px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 38px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 5%;
    font-size: 35px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 5%;
  }

  .loadingText {
    font-size: 35px;
    text-align: center;
    color: white;
  }


  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 550px;
    height: 550px;
    border-radius: 100%;
    border: 3px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    /* Add the glow effect */
    margin-bottom: 30px;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .box1 {
    padding: 30px;
  }

  .name {
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 6px;
    font-size: 57px;
  }

  .name2 {
    text-align: center;
    font-size: 35px;
    color: #01a5c2;
  }

  summary {
    font-size: 37px;
    color: rgb(0, 0, 0);
    padding: 4rem;
    margin-bottom: 4rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 37px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 120px;
    height: 120px;
    margin-left: 40px;
    margin-right: 40px;
    box-shadow: 0 0 10px #01a5c2;
    border: 4px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 570px;
    height: 570px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 40%;
    margin-left: -10%;
    bottom: 0;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 17px;
    letter-spacing: 20px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 70px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 3px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -3px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

@media screen and (min-width: 3840px) {
  body {
    overflow-x: hidden;
  }

  .pay{
    width: 75px;
    height: 75px;
     cursor: pointer;
    transition: transform .3s ease-in-out;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 20px;
  }

  .back{
    width: 70px;
    height: 70px;
    margin-left: 30px;
    cursor: pointer;
    margin-top: 20px;
    transition: transform .3s ease-in-out;
  }

  .alienship {
    width: 35%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
  }

  .aboutShop {
    width: 30%;
    margin-left: auto;
    margin-right: auto;
    margin-top: -15%;
  }

  .boxWrap2Shop {
    padding-top: 7%;
    padding-bottom: 7%;
    background: rgb(7, 0, 14);
    background: radial-gradient(circle, rgba(7, 0, 14, 0.6026785714285714) 0%, rgba(0, 14, 25, 0.7987570028011204) 49%, rgba(0, 18, 33, 0.8407738095238095) 100%), url('./assets/5513594.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .page-loader {
    width: 100%;
    height: 100vh;

    background: #000000;
    z-index: 2000000000000;
    position: fixed;
  }

  .rabbitVid1 {
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    width: 65%;
    margin-top: 15vh;
  }

  .rabbitVid2 {
    width: 80%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .logoM {
    display: none;
  }

  .rabbitVid {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  .rabbitWithFlag {
    width: 27%;
    position: absolute;
    margin-right: 90%;
    margin-top: 24%;
    position: absolute;
    z-index: 1000;
    transform: rotate(7deg);
  }

  .mc__alert--success {
    color: white;
    text-align: center;
    margin-top: -20px;
    font-size: 35px;
  }

  .mc__alert--sending {
    color: white;
    text-align: center;
    margin-top: -20px;
    font-size: 35px;
  }

  .mc__alert--error {
    color: rgb(255, 73, 73);
    text-align: center;
    margin-top: -20px;
    font-size: 35px;
  }

  .closeBtn {
    border-radius: 5px;
    color: rgb(0, 0, 0);
    border: none;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 15px;
    font-size: 35px;

  }

  .about3 {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .subscribe {
    font-size: 45px;
    background-color: #004cff00;
    border: 2px solid white;
    color: white;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    width: 100%;
    padding: 10px;
    transition: transform .2s;
    border-width: 6px;

    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    font-family: 'Anton', sans-serif;
    letter-spacing: 4px;

  }

  .subscribe:hover {
    transform: scale(1.1);
  }

  .boxWrap2Form {
    padding-top: 7%;
    padding-bottom: 7%;
    background: linear-gradient(318deg, rgba(242, 55, 207, 0.16850490196078427) 0%, rgba(62, 153, 242, 0.028448879551820738) 50%, rgba(65, 62, 242, 0.30575980392156865) 100%), url('./assets/mailbg.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    margin-right: 33%;
    margin-top: 5%;
    font-family: 'Anton', sans-serif;

  }

  .closeButton:hover {
    color: rgb(199, 199, 199);
  }

  .inputFormMain {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    font-family: Arial, sans-serif;
    background-color: #007fc800;
    border-width: 7px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    padding: 50px;
    animation: popup 0.3s ease forwards;
  }

  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }


  .inputFormMain h2 {
    text-align: center;
    color: #01a5c2;
    margin-bottom: 80px;
    font-size: 70px;
  }

  .inputMain {
    margin-bottom: 40px;
  }

  .inputMain .inputH {
    color: white;
    font-size: 35px;
  }

  .inputMain input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 35px;

  }

  .inputMain input:focus {
    outline: none;
    border-color: #4285f4;
  }

  .inputMain input::placeholder {
    color: #999;
  }

  .inputFormMain input[type="submit"] {
    background-color: #4285f4;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 10px 20px;
    cursor: pointer;
  }

  .inputFormMain input[type="submit"]:hover {
    background-color: #3367d6;
  }

  .boxWrap2FooterM {
    display: none;
  }

  .rmMobile {
    display: none;
  }

  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    z-index: 1000000;
    padding-top: 13px;
    padding-bottom: 13px;
    background-color: rgba(0, 0, 0, 0.719);
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  }

  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }

  .right {
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    width: 30%;
  }

  .miniBarMain {
    display: none;
  }

  .logoMobile {
    display: none;
  }

  .iconsMob {
    display: none;
  }

  #fontSize {
    font-size: 50px;
    font-family: 'Rajdhani', sans-serif;
    cursor: pointer;
    z-index: 10000;
    font-weight: bold;
    color: #67a7f7;
    translate: 0.2s;
  }

  #fontSize:hover {
    color: #cadef7;
  }

  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .connect div {
    font-family: 'Archivo Black', sans-serif;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
    margin-left: 110px;
    margin-right: 110px;
  }

  .connectBtn {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 20px;
    font-size: 50px;
    padding-left: 65px;
    padding-right: 65px;
    border-width: 7px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 8px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
  }

  .connectBtn:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0%;
    height: 100%;
    background-color: #0084ff;
    transition: width 0.5s ease-in-out;
    z-index: -1;
  }

  .connectBtn:hover {
    background-color: transparent;
  }

  .connectBtn:hover:before {
    width: 100%;
  }

  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }

  .allWrap {
    background-color: black;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;

    justify-content: center;
  }

  .cont {
    background: url('./assets/mobBg1.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    height: max-content;
    height: 100vh;
  }

  .logo {
    width: 230px;
    height: 230px;
    border-radius: 100%;
    border: 8px solid rgb(66, 157, 243);
    box-shadow: 0 0 15px 10px rgba(133, 193, 248, 0.5);
    box-shadow: 0 0 10px 4px rgba(133, 193, 248, 0.5);
    z-index: 12;
  }

  .logoDiv {
    display: flex;
    justify-content: center;
    width: 20%;
  }

  .imgs3 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }

  /*
  .imgs3 img {
    width: 30%;
    height: 30%;
  }
  */
  .leftPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .rightPlanet {
    width: 30%;
    height: 30%;
    display: none;
  }

  .animatePlanetL {
    animation-name: rotate;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(90deg);
    }
  }

  .animatePlanetR {}

  /*.leftGalaxy {
    width: 150vh;
    height: 150vh;
    margin-left: -40vw;
    margin-top: -15%;
  }
  
  .rightGalaxy {
    width: 150vh;
    height: 150vh;
    margin-right: -35vw;
    margin-top: -15%;
  }*/

  .rocketMainDivAnimation {
    animation-name: rotate;
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-iteration-count: 1;
    transform-origin: center center;
  }

  @keyframes rotate {
    0% {
      transform: translateY(0);
    }

    100% {
      transform: translateY(-80px);
    }
  }

  .rocketMainDiv {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 80%;
  }


  .rocket {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .rabbit {
    margin-left: 42%;
    display: flex;
    justify-content: center;
    width: 12%;
    margin-top: -3%;
    position: absolute;
    z-index: 2;
  }

  .fire1 {
    margin-top: 18%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 1s ease-in-out infinite;
  }

  .fire2 {
    margin-top: 17%;
    width: 5%;
    position: absolute;
    margin-left: 45.29%;
    animation: floating 0.3s ease-in-out infinite;
  }

  .fireMain {
    display: flex;
    flex-flow: column nowrap;
  }

  .title {
    font-size: 280px;
    color: white;
    text-align: center;
    letter-spacing: 6px;
    margin-top: 8%;
    text-shadow: 0 -6px 8px #FFF, 0 -6px 15px rgba(0, 195, 255, 0.377), 0 -15px 25px #0099ff63, 0 -23px 45px rgba(0, 119, 255, 0.411);
    font-family: 'Anton', sans-serif;
    text-transform: uppercase;
  }

  @keyframes floating {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 10rem;
    padding-left: 100%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border-width: 9px;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #c723a3);
    border-image-slice: 1;
    -webkit-backdrop-filter: blur(5px);
    background: rgb(107, 215, 254);
    background: linear-gradient(90deg, rgba(107, 215, 254, 0.07886904761904767) 0%, rgba(199, 35, 163, 0.07046568627450978) 100%);
  }

  .ticker-wrap .ticker {
    display: inline-block;
    height: 10rem;
    line-height: 10rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 20s;
    animation-duration: 70s;
  }

  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 4rem;
    font-size: 60px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffffff;
    letter-spacing: 2px;
  }

  /*TICKER 2*/
  @-webkit-keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  @keyframes ticker2 {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }

    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }

  .ticker-wrap2 {
    width: 100%;
    overflow: hidden;
    height: 14rem;
    border-style: solid;
    border-left: rgba(95, 158, 160, 0);
    border-right: rgba(95, 158, 160, 0);
    border-image: linear-gradient(to right, #6bd7fe, #086fbe);
    border-image-slice: 1;
    background-color: #f8d2c600;
  }

  .ticker-wrap2 .ticker2 {
    display: inline-block;
    height: 14rem;
    line-height: 14rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker2;
    animation-name: ticker2;
    -webkit-animation-duration: 20s;
    animation-duration: 700s;
  }

  .ticker-wrap2 .ticker__item2 {
    display: inline-block;
    padding: 0 4rem;
    font-size: 80px;
    background: -webkit-linear-gradient(#eee, #086fbe);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: 2px;
    font-weight: 500;
  }

  /*TICKER 2*/

  .stickyBtn2 {
    width: 65px;
    height: 65px;
  }

  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -5%;
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }

  .boxWrap2Story {
    padding-top: 7%;
    background: rgb(50, 95, 142);
    background: linear-gradient(16deg, rgba(50, 95, 142, 0.5242471988795518) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(15, 88, 156, 0.5102415966386555) 100%);
  }

  .boxWrap2Mint {
    padding-top: 7%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/galaxy.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .boxWrapTeam {
    padding-top: 7%;
    padding-bottom: 7%;
  }


  /*ROADMAP*/

  .boxWrapRm {
    background: rgb(0, 0, 0);

    width: 100%;
    margin-left: auto;
    margin-right: auto;

  }

  .layer1 {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    position: absolute;
  }

  .layer2Box {
    position: absolute;
    margin-top: 20.2%;
    left: 6.9%;
    /* Adjust the distance from the image */
    width: 16.5%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer2:hover+.layer2Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer2 {
    width: 5.5%;
    margin-left: 16.5%;
    margin-top: 17.1%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3 {
    width: 9%;
    margin-left: 19.8%;
    margin-top: 1.3%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer3Box {
    position: absolute;
    margin-top: 1%;
    left: 25%;
    /* Adjust the distance from the image */
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer3:hover+.layer3Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer4 {
    width: 14%;
    margin-left: 37%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer4Box {
    position: absolute;
    margin-top: 2%;
    left: 45%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer4:hover+.layer4Box {
    opacity: 1;
    z-index: 10000000;

  }


  .layer5 {
    width: 20%;
    margin-left: 62.5%;
    margin-top: 2.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer5Box {
    position: absolute;
    margin-top: 2%;
    left: 75%;
    /* Adjust the distance from the image */
    width: 14%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer5:hover+.layer5Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer6 {
    width: 14%;
    margin-left: 43.5%;
    margin-top: 23.9%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer6Box {
    position: absolute;
    margin-top: 31%;
    left: 31%;
    /* Adjust the distance from the image */
    width: 17%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer6:hover+.layer6Box {
    opacity: 1;
    z-index: 10000000;

  }

  .layer7 {
    width: 41%;
    margin-left: 59%;
    margin-top: 22.5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }

  .layer7Box {
    position: absolute;
    margin-left: 75%;
    margin-top: 38%;
    width: 13%;
    /* Adjust the width of the details box */
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  .layer7:hover+.layer7Box {
    opacity: 1;
    z-index: 10000000;
  }

  .rmMain {
    position: relative;
    height: fit-content;
  }

  /*ROADMAP*/


  .boxWrap2Footer {
    padding-top: 7%;
    padding-bottom: 3%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/footerbg.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 56.4%;
  }

  .footer {
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .socialIcons {
    display: flex;
    margin-left: auto;
    margin-bottom: 4%;
  }

  .socialIcons img {
    margin-right: 60px;
    width: 80px;
    height: 80px;
    transition: transform .2s;
  }

  .socialIcons2 {
    display: flex;
     margin-top: auto;
    margin-bottom: auto;  
  }

  .socialIcons2 img {
    margin-right: 60px;
    width: 80px;
    height: 80px;
    transition: transform .2s;
  }

  .socialIcons img:hover {
    transform: scale(1.1);
    cursor: pointer;
  }

  .footer1 {
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    margin-top: 5%;

  }

  .line2 {
    width: 100%;
    height: 1.5px;
    background-color: white;
    margin-bottom: 30px;
  }

  .copyright {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
    font-size: 45px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .terms {
    display: flex;

  }

  .terms div {
    margin-left: 50px;
    color: rgba(255, 255, 255, 0.5);
    font-size: 45px;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .faqBg {

    background: rgb(0, 0, 0);
    background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.07886904761904767) 50%, rgba(0, 0, 0, 1) 100%), url('./assets/team.jpg') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .about {
    display: flex;
    justify-content: space-between;
    flex-flow: row nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }

  .about2 {
    display: flex;
    justify-content: center;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
  }

  .conT {
    font-size: 210px;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    line-height: 220px;
    z-index: 1;
    text-shadow: 0 -4px 6px #FFF, 0 -5px 13px rgba(0, 195, 255, 0.377), 0 -13px 23px #0099ff63, 0 -21px 43px rgba(0, 119, 255, 0.411);
  }

  .conT2 {
    font-size: 170px;
    color: white;
    letter-spacing: 6px;
    font-family: 'Anton', sans-serif;
    line-height: 280px;
    z-index: 1;
    text-align: center;
    margin-bottom: 100px;
    text-shadow: 0 -4px 6px #FFF, 0 -5px 13px rgba(0, 195, 255, 0.377), 0 -13px 23px #0099ff63, 0 -21px 43px rgba(0, 119, 255, 0.411);
  }

  .storyCon {
    font-size: 48px;
    width: 60%;
    color: white;
    z-index: 1000;
  }

  .storyCon p {
    color: white;
    margin-top: 80px;
    margin-bottom: 80px;
  }

  .astronaut {
    width: 20%;
    margin-left: 32%;
    margin-top: -25%;
    animation: float 6s ease-in-out infinite;
  }

  .mail {
    width: 20%;
    animation: float 6s ease-in-out infinite;
  }

  @keyframes float {
    0% {
      transform: translateX(-50%) translateY(0);
    }

    50% {
      transform: translateX(-50%) translateY(-20px);
    }

    100% {
      transform: translateX(-50%) translateY(0);
    }
  }

  .btn {
    width: 55%;
    margin-top: -10%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    cursor: pointer;
  }

  .window {
    width: 100%;
    position: absolute;
    margin-left: 0;
    top: 0;
  }

  @keyframes borderGlow {
    0% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 30px 20px #5391e4;
    }

    100% {
      box-shadow: 0 0 0 rgba(255, 255, 255, 0.7);
    }
  }

  .mintDiv {
    margin-top: 5%;
    padding-top: 3%;
    padding-bottom: 3%;
    width: 45%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    background: rgb(124, 185, 249);
    background: linear-gradient(130deg, rgba(124, 185, 249, 0.11528361344537819) 0%, rgba(138, 223, 252, 0.10688025210084029) 50%, rgba(11, 175, 93, 0.11808473389355745) 100%);
    border-width: 5px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .mintCon {
    margin-top: 2%;
    justify-content: center;
    flex-flow: column nowrap;
    display: flex;
  }

  .parrot {
    width: 50%;
    border: 1px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 5px 5px rgba(0, 217, 255, 0.24);
    /* Add the glow effect */
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);
    border-radius: 100%;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .totalSupply {
    text-align: center;
    font-size: 140px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(255, 255, 255);
    letter-spacing: 1px;
    margin-top: 1%;
    z-index: 10000000;
    font-weight: 500;
  }

  .price {
    text-align: center;
    font-size: 55px;
    color: rgb(255, 255, 255);
    z-index: 10000000;
  }

  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    margin-right: auto;
    margin-left: auto;
    z-index: 10000000;
  }

  .center {
    margin-top: auto;
    margin-bottom: auto;
    z-index: 100000000000;
  }

  .mintbuttondiv {
    text-align: center;
  }

  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
    z-index: 10000000;

  }

  .triangle {
    width: 100px;
    height: 100px;
    cursor: pointer;
    z-index: 100;
  }

  .triangle2 {
    width: 100px;
    height: 100px;
    rotate: 180deg;
    cursor: pointer;
    z-index: 100;
  }

  .btnfos-0-2 {
    font-size: 90px;
    margin-left: 90px;
    margin-right: 90px;
    padding: 5px;
    width: 180px;
    height: 180px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 6px solid;
  }

  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;

    cursor: pointer;
    background-color: #3a9ae800;
    color: #1cb5f1;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    border: white 2px solid;
  }

  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
    color: white;
    border: #1cb5f1 2px solid;
  }

  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }

  .btnfos-0-3 {
    background-color: #0084ff00;
    color: rgb(0, 0, 0);
    padding: 25px;
    font-size: 75px;
    width: 650px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #99e2ff, #349dff);
    border-image-slice: 1;
    color: white;
    letter-spacing: 4px;
    font-family: 'Anton', sans-serif;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .btnfos-0-3:hover {
    background-color: rgb(241, 34, 34)
  }

  .btnfos-0-3:active {
    background-color: rgb(241, 34, 34);
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  .nftamount {
    color: #02ace0;
    font-size: 220px;
    font-family: 'Anton', sans-serif;
    text-shadow: 0 0 40px #02ace0ad;
  }

  .mintbuttondiv {
    text-align: center;
    z-index: 10000000;
  }


  .success-message {
    color: #4caf50;
    border-radius: 5px;
    text-align: center;
    margin-top: 5%;

  }

  .success-message h2 {
    font-size: 55px;
    margin-top: 0;
  }

  .success-message p {
    font-size: 50px;
    margin-bottom: 0;
  }

  .errorMessage {
    margin-top: 5%;
    font-size: 50px;
    color: #fc4741;
    text-align: center;
  }

  .loadingContainer {
    margin-top: 5%;
  }

  .loadingText {
    font-size: 50px;
    text-align: center;
    color: white;
  }


  .loadTextSub {
    text-align: center;
    color: rgba(255, 255, 255, 0.815);
    font-size: 16px;
    padding-top: 5px;
  }

  /* loading dots */


  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }

  @keyframes dots {

    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    40% {
      color: rgb(2, 128, 187);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    60% {
      text-shadow:
        .25em 0 0 rgb(2, 128, 187),
        .5em 0 0 rgba(0, 0, 0, 0);
    }

    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }

  .teamSection {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }

  .box1 img {
    width: 830px;
    height: 830px;
    border-radius: 100%;
    border: 6px solid rgb(70, 186, 253);
    /* Set the border style */
    box-shadow: 0 0 7px 7px rgba(70, 186, 253, 0.507);

    /* Add the glow effect */
    margin-bottom: 30px;
    background: rgb(0, 73, 139);
    background: linear-gradient(337deg, rgba(0, 73, 139, 0.3169642857142857) 0%, rgba(9, 76, 133, 0.40379901960784315) 45%);
  }

  .box1 {
    padding: 30px;
  }

  .name {
    text-align: center;
    font-family: 'Anton', sans-serif;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #ffffff50;
    letter-spacing: 8px;
    font-size: 85px;
  }

  .name2 {
    text-align: center;
    font-size: 55px;
    color: #01a5c2;
  }

  summary {
    font-size: 45px;
    color: rgb(0, 0, 0);
    padding: 5rem;
    margin-bottom: 5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
    font-weight: 500;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #aadbf7, #4e84fa);
    border-image-slice: 1;
    background-color: #2525258f;
  }

  details>summary::-webkit-details-marker {
    display: none;
  }

  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }

  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }

    100% {
      opacity: 1;
      margin-top: 0px
    }
  }

  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }

  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 45px;
  }

  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }

  .icons {
    display: flex;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 2%;
    margin-bottom: 2%;
  }

  .icons img {
    width: 170px;
    height: 170px;
    margin-left: 60px;
    margin-right: 60px;
    box-shadow: 0 0 10px #01a5c2;
    border: 4px solid #0080c8;
    border-radius: 100%;
    transition: transform .2s;
  }

  .icons img:hover {
    transform: scale(1.2);
    cursor: pointer;

  }

  .logoF {
    width: 750px;
    height: 750px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);

  }

  .rabbitsLine {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -40%;
    bottom: 0;
    width: 90%;
    height: 70vh;
  }

  .r1 {
    width: 300px;
    height: 300px;
    position: absolute;
    margin-left: -40%;
    margin-top: 12%;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r2 {
    width: 400px;
    height: 400px;
    position: absolute;
    margin-left: -70%;
    margin-top: 13.5%;
    margin-top: 3.5%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r3 {
    width: 400px;
    height: 400px;
    margin-left: 90%;
    position: absolute;
    margin-left: 95%;
    margin-top: 3.5%;
    z-index: 1;
    opacity: 0;
    animation: fade-up 1s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }


  .r4 {
    width: 300px;
    height: 300px;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-left: 55%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    margin-top: 12%;
    animation: fade-up 2s ease-in-out forwards;
  }

  @keyframes fade-up {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .r5 {
    width: 400px;
    height: 400px;
    margin-left: -6%;
    margin-right: -6%;
    position: absolute;
  }

  .carrotDiv {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #0084ff;
  }

  .carrot {
    width: 800px;
  }

  .faqR {
    width: 40%;
    margin-left: -10%;
    bottom: 0;
  }

  .starGlow {
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    animation: glow 2s ease-in-out infinite;
  }

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px #fff;
    }

    50% {
      box-shadow: 0 0 20px #fff, 0 0 30px #ffca18, 0 0 40px #ffca18, 0 0 50px #ffca18;
    }

    100% {
      box-shadow: 0 0 10px #fff;
    }
  }

  .os {
    font-family: 'Anton', sans-serif;
    background-color: #3e96ee00;
    color: white;
    width: 100%;
    border: 3px rgb(255, 255, 255) solid;
    border-radius: 7px;
    padding: 17px;
    letter-spacing: 30px;
    position: relative;
    overflow: hidden;
    transition: background-color 0.5s ease-in-out;
    margin-top: 20px;
    border-width: 6px;
    border-style: solid;
    border-image: linear-gradient(to right, #5cccf8, #0084ff);
    border-image-slice: 1;
  }

  .os:hover:before {
    width: 100%;
  }

  .glitch-wrapper {
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
  }

  .glitch {
    color: rgb(255, 255, 255);
    font-size: 110px;
    text-transform: upercase;
    position: relative;
    display: inline-block;

  }

  .glitch::before,
  .glitch::after {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .glitch::before {
    left: 3px;
    text-shadow: -2px 0 #1ec5fb;
    clip: rect(24px, 550px, 90px, 0);
    -webkit-animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
    animation: glitch-anim-2 2.5s infinite linear alternate-reverse;
  }

  .glitch::after {
    left: -3px;
    text-shadow: -2px 0 #ba2e79;
    clip: rect(85px, 550px, 140px, 0);
    -webkit-animation: glitch-anim 2.5s infinite linear alternate-reverse;
    animation: glitch-anim 2.5s infinite linear alternate-reverse;
  }

  @-webkit-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-o-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-moz-keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @keyframes glitch-anim {
    0% {
      clip: rect(110px, 9999999px, 74px, 0);
    }

    4.16666667% {
      clip: rect(135px, 9999999px, 143px, 0);
    }

    8.33333333% {
      clip: rect(100px, 9999999px, 100px, 0);
    }

    12.5% {
      clip: rect(38px, 9999999px, 24px, 0);
    }

    16.66666667% {
      clip: rect(125px, 9999999px, 83px, 0);
    }

    20.83333333% {
      clip: rect(149px, 9999999px, 63px, 0);
    }

    25% {
      clip: rect(12px, 9999999px, 104px, 0);
    }

    29.16666667% {
      clip: rect(68px, 9999999px, 7px, 0);
    }

    33.33333333% {
      clip: rect(77px, 9999999px, 20px, 0);
    }

    37.5% {
      clip: rect(99px, 9999999px, 6px, 0);
    }

    41.66666667% {
      clip: rect(27px, 9999999px, 99px, 0);
    }

    45.83333333% {
      clip: rect(58px, 9999999px, 107px, 0);
    }

    50% {
      clip: rect(108px, 9999999px, 77px, 0);
    }

    54.16666667% {
      clip: rect(58px, 9999999px, 87px, 0);
    }

    58.33333333% {
      clip: rect(142px, 9999999px, 112px, 0);
    }

    62.5% {
      clip: rect(124px, 9999999px, 125px, 0);
    }

    66.66666667% {
      clip: rect(61px, 9999999px, 53px, 0);
    }

    70.83333333% {
      clip: rect(117px, 9999999px, 74px, 0);
    }

    75% {
      clip: rect(21px, 9999999px, 74px, 0);
    }

    79.16666667% {
      clip: rect(117px, 9999999px, 70px, 0);
    }

    83.33333333% {
      clip: rect(87px, 9999999px, 122px, 0);
    }

    87.5% {
      clip: rect(95px, 9999999px, 9px, 0);
    }

    91.66666667% {
      clip: rect(121px, 9999999px, 75px, 0);
    }

    95.83333333% {
      clip: rect(121px, 9999999px, 115px, 0);
    }

    100% {
      clip: rect(101px, 9999999px, 72px, 0);
    }
  }

  @-webkit-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-o-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @-moz-keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  @keyframes glitch-anim-2 {
    6.66666667% {
      clip: rect(76px, 9999999px, 132px, 0);
    }

    10% {
      clip: rect(13px, 9999999px, 82px, 0);
    }

    13.33333333% {
      clip: rect(97px, 9999999px, 2px, 0);
    }

    16.66666667% {
      clip: rect(1px, 9999999px, 147px, 0);
    }

    20% {
      clip: rect(113px, 9999999px, 124px, 0);
    }

    23.33333333% {
      clip: rect(132px, 9999999px, 130px, 0);
    }

    26.66666667% {
      clip: rect(120px, 9999999px, 32px, 0);
    }

    30% {
      clip: rect(2px, 9999999px, 10px, 0);
    }

    33.33333333% {
      clip: rect(76px, 9999999px, 46px, 0);
    }

    36.66666667% {
      clip: rect(140px, 9999999px, 79px, 0);
    }

    40% {
      clip: rect(37px, 9999999px, 67px, 0);
    }

    43.33333333% {
      clip: rect(133px, 9999999px, 95px, 0);
    }

    46.66666667% {
      clip: rect(6px, 9999999px, 16px, 0);
    }

    50% {
      clip: rect(98px, 9999999px, 123px, 0);
    }

    53.33333333% {
      clip: rect(42px, 9999999px, 88px, 0);
    }

    56.66666667% {
      clip: rect(77px, 9999999px, 110px, 0);
    }

    60% {
      clip: rect(22px, 9999999px, 145px, 0);
    }

    63.33333333% {
      clip: rect(47px, 9999999px, 75px, 0);
    }

    66.66666667% {
      clip: rect(109px, 9999999px, 135px, 0);
    }

    70% {
      clip: rect(118px, 9999999px, 40px, 0);
    }

    73.33333333% {
      clip: rect(74px, 9999999px, 141px, 0);
    }

    76.66666667% {
      clip: rect(59px, 9999999px, 100px, 0);
    }

    80% {
      clip: rect(14px, 9999999px, 32px, 0);
    }

    83.33333333% {
      clip: rect(22px, 9999999px, 28px, 0);
    }

    86.66666667% {
      clip: rect(106px, 9999999px, 74px, 0);
    }

    90% {
      clip: rect(33px, 9999999px, 97px, 0);
    }

    93.33333333% {
      clip: rect(94px, 9999999px, 29px, 0);
    }

    96.66666667% {
      clip: rect(108px, 9999999px, 87px, 0);
    }

    100% {
      clip: rect(5px, 9999999px, 14px, 0);
    }
  }

  .road {
    margin-top: -5%;
    width: 100%;
  }

  .footerLine {
    width: 100%;
    margin-top: -7%;
  }
}

.box1 {
  padding: 30px;
  z-index: 100000000000000;

}